@import 'ng-animation.scss';

body.solutions {


    header.hero {
        overflow: hidden;
        padding-bottom: 0;

        .container {
            border: 0;
            margin: 0;
            width: 100%;
            height: 38vw;

            img {}

            h1 {
                font-size: 45px;
                font-weight: bold; }

            h2 {
                font-size: 35px;
                color: $allstate_warning; }

            img {
                width: 100%; } } }

    @media (max-width: 768px) {

        header.hero {

            .container {
                margin-top: 20px;

                img {
                    margin: 0; }


                h1 {
                    font-size: 20px;
                    padding: 0; }

                h2 {
                    font-size: 18px;
                    padding: 0; } } } }



    .divider {
        background-color: #00467f;
        width: 100%;
        border: 0;
        padding-bottom: 15px;
        padding-top: 15px;
        margin: 0;

        h2 {
            color: white;
            font-size: 1.5vw;
            padding-bottom: 15px;
            font-weight: 300;
            line-height: 45px;

            @media (max-width: 768px) {
                font-size: 20px; } }

        h3 {
            color: white; }

        h4 {
            color: white;
            font-weight: 300;
            font-size: 1vw; } }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        .divider {
            padding: 0;

            .row {
                margin: 0;

                h2 {
                    line-height: 25px; } } } }

    @media (max-width: 768px) {

        .divider {
            padding: 0;
            margin-bottom: 10px;

            .row {
                margin: 0;

                h2 {
                    font-size: 3.9vw;
                    line-height: 25px; } } } }


    section.solutions-technology {

        .row {
            margin-top: 70px;
            margin-bottom: 70px;

            .product {
                overflow:hidden {}
                transition: left 4s ease-in-out 1s;
                animation: fadeIn 2s;
                -webkit-animation: fadeIn 2s;
                -moz-animation: fadeIn 2s;
                -o-transition: fadeIn 2s;

                img {
                    width: 50px;
                    height: 50px;
                    margin-top: 15px;
                    padding-top: 0; }

                h2 {
                    color: #00467f;
                    font-size: 28px; } }

            .description {
                animation: fadeIn 2s;
                -webkit-animation: fadeIn 2s;
                -moz-animation: fadeIn 2s;
                -o-transition: fadeIn 2s;

                h4 {
                    color: #00467f;
                    font-size: 22px;
                    padding-top: 10px;
                    line-height: 35px;
                    margin-right: 200px;
                    padding-top: 0; } }

            // .description:hover
            //
            //     h4
 }            //         font-size: 1.6vw

        hr {
            width: 75vw;
            border-bottom: 2px solid $background_light; } }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        section.solutions-technology {

            .row {

                .product {
                    img {
                        width: 60px;
                        height: 60px;
                        margin-top: 30px; } }

                .description {
                    h4 {
                        font-size: 18px;
                        padding: 0;
                        line-height: 33px;
                        margin-right: 100px; } } } } }

    @media (max-width: 768px) {
        section.solutions-technology {

            .row {
                margin-bottom: 20px;

                .product {
                    h2 {
                        font-size: 22px; }

                    img {
                        width: 40px;
                        height: 40px; } }

                .description {

                    h4 {
                        font-size: 12px;
                        padding-top: 0;
                        padding-left: 5%;
                        line-height: 20px;
                        margin-right: 0; }

                    br {
                        display: none; } } } } }


    .video-thumb {
        // position: relative
        // text-align: center
        // background-color: white
        // // width: 800px
        padding-bottom: 0px;
        // border: 2px solid #00467f
        // box-shadow: 0 10px 2px -2px #bdbdbd

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            border: none; }

        @media (max-width: 768px) {
            width: 100%;
            padding: 0;
            margin: 0; }

        .before-play-button {
            color: #00467f;
            padding-top: 100px;
            padding-bottom: 150px;

            @media (max-width: 768px) {
                padding-top: 20px;
                font-size: 20px; } }

        .after-play-button {
            color: #ee8722;

            @media (max-width: 768px) {
                font-size: 20px;
                padding-bottom: 50px; } }

        .play-btn {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -30px;
            margin-top: -30px;
            height: 60px;
            width: 60px;
            color: #ee8722;
            border: 3px solid #ee8722;
            border-radius: 30px;
            font-size: 28px;
            line-height: 60px;
            text-align: center;
            background-color: transparent;
            @include transition(.3s ease all);
            overflow: hidden;

            .icon-play {
                position: absolute;
                top: -1px;
                left: 16px;

                &:before {
                    margin: 0; } }

            .btn-text {
                color: transparent;
                display: inline-block;
                width: 200px;
                overflow: hidden;
                font-size: 18px;
                line-height: 57px;
                @include transition(.3s ease width); }

            &:hover {
                width: 200px;
                margin-left: -100px;
                background-color: #d3d3d3;
                border-color: transparent;
                cursor: pointer;

                .btn-text {
                    color: #00467f;
                    margin-left: 0; } } } }

    .stripe {
        min-height: 0;
        padding: 80px 0 120px;

        .solutions-signup {
            padding-bottom: 40px; }

        hr.crm-line {
            width: 900px;
            border-bottom: 2px solid $background_light; }

        h3 {
            margin-top: -10px;
            margin-bottom: 10px;
            color: black;
            font-weight: 300; }

        p {
            margin-bottom: 40px;
            color: black;
            font-size: 18px;
            font-weight: 400; }

        .solutions-text {
            margin-top: 40px;
            margin-bottom: -40px; }

        img {
            max-width: 100%;
            box-shadow: 0 10px 2px -2px #bdbdbd; }

        .crm {
            box-shadow: none; }

        @media (max-width: 768px) {
            padding: 40px 0;

            h3 {
                margin-top: 0;
                font-size: 20px; }

            p {
                margin-bottom: 10px;
                font-size: 14px; }

            .solutions-text {
                margin-top: 10px;
                margin-bottom: 10px; }

            img {
                margin: 20px 0; } } } }

.loading-progress {
    position: relative;
    @include transition(.5s ease background-color .5s);

    .loading-content {
        @include transition(.2s ease opacity); }

    .progress {
        background-color: $allstate_secondary;
        z-index: 1100;
        position: fixed;
        top: 0px;
        left: 0;
        height: 3px;
        width: 0%;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        @include transition(.2s ease width);
        display: none;
        opacity: 0; }

    &.loading-progress-loading {
        .loading-content {
            @include transition(none);
            opacity: .5; }

        .progress {
            display: block;
            @include transition(.2s ease width);
            opacity: 1; } } }

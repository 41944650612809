.dropdown-menu {
    margin-top: 13px;
    padding: 5px 0;
    background-color: $allstate_primary;
    box-shadow: none;
    border: 0;
    border-radius: 5px;
    font-size: 14px;
    font-weight: normal;
    overflow: hidden;
    outline: 0;
    z-index: 1020;

    & > li {
        background-color: $allstate_primary;

        &.selected > a {
            background: $allstate_secondary;
            @include reset-filter(); }

        & > a {
            padding: 10px 20px;
            color: white;
            text-align: left;
            font-size: 14px;
            cursor: pointer;

            &:hover, &:focus, &:active {
                background: $allstate_secondary;
                @include reset-filter();
                outline: 0;
                color: white;
                cursor: pointer; }

            [class^="icon-"] {
                margin-right: 5px;
                color: white; } } }

    & > .disabled, & > .disabled.selected {
        & > a {
            &, &:hover, &:focus, &:active {
                background: transparent;
                color: white; } } }

    .active {
        background: $allstate_secondary;

        & > a {
            &, &:hover, &:focus, &:active {
                background: transparent;
                @include reset-filter(); } }

        &.disabled {
            background: transparent;
            cursor: not-allowed; } } }


.dropdown-menu-wrapper {
    position: absolute;

    &:after {
        @include dropdown-arrow-top($allstate_primary);
        bottom: -13px; }

    .dropdown-menu {
        display: block !important;
        visibility: visible !important; } }

.form-control {
    height: 40px;
    border: 2px solid white;
    padding: 4px 10px;
    border-radius: 3px;
    box-shadow: none;
    color: $color_text;
    @include transition(none);
    text-align: left;

    &:focus, &:active {
        box-shadow: none;
        border-color: $allstate_secondary; }

    &[disabled] {
        background-color: lighten($background_light, 3%);
        border-color: lighten($background_light, 3%);
        color: lighten($color_text, 20%); }

    &.ui-droppable {
        &.active {
            border-color: darken($background_light, 5%);
            border-style: dashed; }

        &.hover {
            border-color: $allstate_success; } }

    &.dynamic {
        @include transition(none); }

    &[bs-select] {
        padding-right: 30px;
        position: relative;

        .caret {
            position: absolute;
            top: 50%;
            margin-top: -2px;
            right: 12px;
            border-top-color: lighten($color_text, 35%); }

        &:hover {
            .caret {
                border-top-color: lighten($color_text, 10%); } } } }

.control-label {
    font-size: 14px;
    color: #00467f;

    [class^="text-"] {
        margin-left: 2px; } }

.help-block {
    margin-bottom: 0; }

#sign-up-form {
    width: 70%;
    margin: 0 15%;

    .badge {
        color: white;
        background-color: #00467f;
        padding: 3px 6px;
        margin-right: 5px;
        margin-top: -2px;
        border-radius: 3px; }

    @media (max-width: 768px) {
        width: auto;
        margin: auto; } }

// General

.btn {
    height: 40px;
    line-height: 36px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: bold;
    background-color: $background_light;
    border: 2px solid $background_light;
    border-radius: 3px;
    color: lighten( $allstate_primary, 40% );

    &:hover, &:focus, &:active, &.active {
        background-color: darken( $background_light, 3% );
        border-color: darken( $background_light, 3% );
        color: lighten( $allstate_primary, 40% );
        @include box-shadow( none );
        outline: 0; }

    &:focus {
        border-color: darken( $background_light, 10% ); }

    &.btn-icon {
        text-align: center;
        padding: 0; } }

// Colors

.btn-primary {
    background-color: $allstate_secondary;
    border-color: $allstate_secondary;
    color: white;

    &:hover, &:focus, &:active {
        background-color: darken( $allstate_secondary, 6% );
        border-color: darken( $allstate_secondary, 6% );
        @include box-shadow( none );
        color: white; }

    &:focus {
        border-color: darken( $allstate_secondary, 12% ); }

    &.btn {
        &, &:hover, &:focus, &:active {
            .caret {
                border-top-color: white; } } } }

.btn-warning {
    background-color: $allstate_warning;
    border-color: $allstate_warning;
    color: white;

    &:hover, &:focus, &:active {
        background-color: darken( $allstate_warning, 6% );
        border-color: darken( $allstate_warning, 6% );
        @include box-shadow( none );
        color: white; }

    &:focus {
        border-color: darken( $allstate_warning, 12% ); } }

.btn-navbar {
    background-color: $allstate_warning;
    border-color: $allstate_warning;
    color: white;
    width: 110px;
    height: 43px;
    font-size: 17px;

    &:hover, &:focus, &:active {
        background-color: darken( $allstate_warning, 6% );
        border-color: darken( $allstate_warning, 6% );
        @include box-shadow( none );
        color: white; }

    &:focus {
        border-color: darken( $allstate_warning, 12% ); } }

.btn-divider {
    background-color: $allstate_warning;
    border-color: $allstate_warning;
    color: white;
    width: 160px;
    height: 50px;
    font-size: 50px; }

.btn-footer {
    background-color: $allstate_warning;
    border-color: $allstate_warning;
    color: white;
    width: 220px;
    height: 55px;
    font-size: 18px;
    padding-top: 4px;

    &:hover, &:focus, &:active {
        background-color: darken( $allstate_warning, 6% );
        border-color: darken( $allstate_warning, 6% );
        @include box-shadow( none );
        color: white; }

    &:focus {
        border-color: darken( $allstate_warning, 12% ); }

    &:hover, &:focus, &:active {
        background-color: darken( $allstate_warning, 6% );
        border-color: darken( $allstate_warning, 6% );
        @include box-shadow( none );
        color: white; }

    &:focus {
        border-color: darken( $allstate_warning, 12% ); } }

.btn-login {
    background-color: white;
    border-color: #00467f;
    color: #00467f;
    width: 220px;
    height: 55px;
    font-size: 18px;
    padding-top: 4px;
    border-radius: 3px;

    &:hover, &:focus, &:active {
        background-color: darken( white, 6% );
        border-color: darken( white, 6% );
        @include box-shadow( none );
        color: #00467f; }

    &:focus {
        border-color: darken( #00467f, 12% ); }

    &:hover, &:focus, &:active {
        background-color: darken( white, 6% );
        border-color: darken( #00467f, 6% );
        @include box-shadow( none );
        color: #00467f; }

    &:focus {
        border-color: darken( #00467f, 12% ); } }

.btn-danger {
    background-color: $allstate_error;
    border-color: $allstate_error;
    color: white;

    &:hover, &:focus, &:active {
        background-color: darken( $allstate_error, 6% );
        border-color: darken( $allstate_error, 6% );
        @include box-shadow( none );
        color: white; }

    &:focus {
        border-color: darken( $allstate_error, 15% ); } }

.btn-success {
    background-color: $allstate_success;
    border-color: $allstate_success;
    color: white;

    &:hover, &:focus, &:active {
        background-color: darken( $allstate_success, 6% );
        border-color: darken( $allstate_success, 6% );
        @include box-shadow( none );
        color: white; }

    &:focus {
        border-color: darken( $allstate_success, 12% ); } }

.btn-dark {
    background-color: darken( $allstate_primary, 3% );
    border-color: darken( $allstate_primary, 3% );
    color: darken( $color_text, 5% );

    &:hover, &:focus, &:active {
        background-color: darken( $allstate_primary, 5% );
        border-color: darken( $allstate_primary, 5% );
        @include box-shadow( none );
        color: darken( $color_text, 5% ); }

    &:focus {
        border-color: lighten( $allstate_primary, 5% ); } }

.btn-outline {
    background-color: transparent;
    border-color: darken( $background_light, 5% );
    color: white;
    width: 220px;
    height: 55px;
    font-size: 18px;
    padding-top: 0px;


    &:hover, &:focus, &:active {
        background-color: transparent;
        border-color: darken( $background_light, 15% ); }

    &.static {
        &, &:hover {
            border-color: darken( $background_light, 5% );
            cursor: default; } }

    &.active {
        &, &:hover, &:focus, &:active {
            background-color: transparent;
            color: $color_text;
            border-color: darken($background_light, 20%); } }

    &.bg-dark {
        border-color: lighten($allstate_warning, 10%);
        color: white;

        &:hover, &:focus, &:active {
            border-color: lighten($allstate_warning, 20%);
            background-color: lighten($allstate_warning, 20%);
            color: white; } } }

.btn-link {
    border-color: transparent;
    background-color: transparent;

    &:hover, &:focus, &:active {
        border-color: transparent;
        background-color: transparent;
        text-decoration: none;
        color: $allstate_secondary; } }

.nav > li > a.btn {
    padding: 0 16px;
    background-color: inherit; }

.nav > li.active > a.btn-outline {
    background-color: #fff;
    color: $color_text;
    border-color: darken( $background_light, 15% ); }


// Sizes

.btn-lg {
    height: 50px;
    line-height: 46px;
    padding: 0 36px;
    font-size: 18px; }

.btn-sm {
    height: 30px;
    line-height: 26px;
    padding: 0 6px; }

// Button Groups

.btn-toolbar {
    .btn-group {
        float: none; } }

.btn-group {
    [class^="icon-"] {
        font-size: 14px; }

    .btn-sm {
        width: 30px;
        padding: 0; }

    .btn-group {
        & + .btn {
            margin-left: 0; } }

    &.btn-group-outline {
        .btn:not(.active) {
            background-color: transparent; }

        .btn + .btn {
            margin-left: -2px; } }

    .btn + .btn {
        margin-left: 0; } }

// Special Hover States

.btn-hover-primary {
    &:hover, &:active, &:focus {
        background-color: $allstate_secondary !important;
        border-color: $allstate_secondary !important;
        color: white !important; }

    &:focus {
        border-color: darken( $allstate_secondary, 12% ) !important; } }

.btn-hover-success {
    &:hover, &:active, &:focus {
        background-color: $allstate_success;
        border-color: $allstate_success;
        color: white !important; }

    &:focus {
        border-color: darken( $allstate_success, 12% ) !important; } }

.btn-hover-error {
    &:hover, &:active, &:focus {
        background-color: $allstate_error;
        border-color: $allstate_error;
        color: white !important; }

    &:focus {
        border-color: darken( $allstate_error, 15% ) !important; } }

.btn-hover-warning {
    &:hover, &:active, &:focus {
        background-color: $allstate_warning;
        border-color: $allstate_warning;
        color: white !important; }

    &:focus {
        border-color: darken( $allstate_warning, 12% ) !important; } }

.video-player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 1050;
    border-width: 0px;
    border-style: solid;
    border-color: darken(#00467f, 10%);
    background-color: rgba(62, 68, 79, 0);
    @include transition(.3s ease border-width, .3s ease background-color, height 0s 0.3s);
    overflow: hidden;

    &.open {
        height: 100%;
        border-width: 80px 30px;
        background-color: rgba(62, 68, 79, .5);
        @include transition(.3s ease border-width, .3s ease background-color);

        iframe {
            opacity: 1; } }

    iframe {
        opacity: 0;
        border: 0 !important;
        height: 100%;
        width: 100%;
        @include transition(.3s ease opacity); } }

.video-close-btn {
    @include transition(.3s ease transform);
    position: fixed;
    display: none;
    height: 40px;
    line-height: 40px;
    text-align: center;
    top: 20px;
    right: 30px;
    font-size: 18px;
    color: $background_light;
    z-index: 1055;
    padding-right: 60px;

    &:hover {
        color: white;
        cursor: pointer; }

    .icon-denied {
        position: absolute;
        font-size: 38px;
        height: 40px;
        width: 40px;
        top: 0;
        right: 0;

        &:before {
            margin: 0; } }

    &.open {
        @include transition(.3s ease transform);
        display: block; }

    &.open-add, &.open-remove {
        display: block; }

    &.open-add, &.open-remove.open-remove-active {
        @include translate3d(40px, -80px, 0); }

    &.open-add-active, &.open-remove {
        @include translate3d(0, 0, 0); } }

// Variables
$allstate_primary: #3e444f;
$allstate_secondary: #0096d6;
$allstate_error: #ee3424;
$allstate_warning: #ee8722;
$allstate_success: #6db33f;

$background_light: #eaedf1;
$color_text: #6d717a;
$color_text_inverted: #ffffff;

$leadvantage_primary: #0037c2;
$leadvantage_secondary: #868990;
$leadvantage_success: #95D600;

$material-font-family: 'Roboto', sans-serif;

@import "../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap/mixins";
// injector

@import 'about/sass/about.sass';

@import 'contact/sass/contact.sass';

@import 'faq/sass/faq.sass';

@import 'home/sass/home.sass';

@import 'home/sass/test.sass';

@import 'index/sass/index.sass';

@import 'privacy-policy/sass/privacy-policy.sass';

@import 'solutions/sass/solutions.sass';

@import 'support/sass/support.sass';

@import 'terms-of-service/sass/terms-of-service.sass';

// endinjector
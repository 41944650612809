@mixin dropdown-arrow-top($color) {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 20px;
    bottom: -15px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $color; }

@mixin border-radius($radius) {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius; }

.testimonials {
    background: $background-light;
    position: relative;

    .container {
        height: 100%;
        position: relative; }

    .avatar-container {
        height: 90px;
        width: 90px;
        border-radius: 50%;
        position: absolute;
        top: -45px;
        left: 50%;
        margin-left: -45px;
        background-color: #0096d6; }

    .nav {
        width: 240px;
        margin-left: -110px;
        left: 50%;
        position: absolute;
        bottom: 20px; }


    ul {
        padding: 0px 13px 0px 20px;
        margin: 0;
        border: 0;

        & > li {
            list-style-position: outside;
            // text-indent: 0px
            & > a {
                background-color: transparent;
                text-align: center;
                // padding-left: -10px
                padding-top: 20px;
                text-indent: -17px; }

            &.active {
                & > a {
                    background-color: transparent;


                    .dot {
                        background-color: transparent;
                        border-color: #0096d6;
                        height: 15px;
                        width: 15px;
 } } }                        // letter-spacing: -10px

            .dot {
                display: inline-block;
                height: 12px;
                width: 12px;
                border: 2px solid transparent;
                border-radius: 50%;
                background-color: #0096d6;
                margin: 0;
                @include transition(.3s ease background-color, .3s ease border-color); } } }


    .testimonial {
        padding-top: 55px;
        padding-bottom: 55px;
        @include transition(.5s ease opacity);

        img {
            height: 80px;
            width: 80px;
            border-radius: 50%;
            position: absolute;
            top: -40px;
            left: 50%;
            margin-left: -40px;
            border-radius: 50%; }

        blockquote {
            border-left: 0;
            padding: 0;
            text-align: center;

            p {
                font-size: 16px;
                line-height: 1.5;
                color: lighten($color-text, 10%);
                text-align: center;
 }                // font-style: italic

            footer {
                color: $color-text;
                text-align: center;
                font-weight: bold;

                .text-muted {
                    font-weight: normal;
                    color: lighten($color-text, 20%); } } }

        &.ng-hide-add, &.ng-hide-remove {
            @include transition(.5s ease opacity);
            display: block !important; }

        &.ng-hide-add.ng-hide-add-active, &.ng-hide-remove {
            opacity: 0; }

        &.ng-hide-remove.ng-hide-remove-active, &.ng-hide-add {
            opacity: 1; }

        &.ng-hide-add {
            position: absolute;
            top: 0;
            left: 15px;
            right: 15px; } }

    @media screen and (max-width: 992px) {
        .testimonial {
            padding-bottom: 20px; }

        .nav {
            display: none; } } }

body.faq {
    header.hero {
        @media (max-width: 992px) {
            padding-bottom: 0; }
        .contact-container {
            border: 0;
            margin: 0;
            width: 100%;
            background: url("/images/hero_images/contact_hero_transparent.jpg") no-repeat center center;
            background-size: cover;
            padding-bottom: 0px; }
        .faq {
            background-color: #fff;
            margin: 50px 0;
            padding: 47px 23px;
            border: 1px solid #0097d3;
            h3 {
                -webkit-font-smoothing: antialiased;
                font-size: 26px;
                color: #0096d6;
                font-weight: bolder;
                line-height: 30px;
                border-bottom: 2px solid #ee8722;
                line-height: 75px;
                margin-top: 0;
                margin-bottom: 36px; }
            .question-answer {
                margin: 0;
                padding: 0;
                list-style-type: none;
                margin: 20px 0;
                &.last {
                    margin-bottom: 50px; }
                .question,.answer {
                    font-weight: bold;
                    font-size: 14px;
                    color: #00467f; }
                .question {
                    line-height: 30px; }
                .question ul {
                    margin: 0;
                    padding: 0; }
                .answer {
                    font-weight: normal;
                    list-style-type: none;
                    line-height: 22px;
                    a {
                        color: #00467f; } }
                .answer.list-style {
                    list-style-type: disc;
                    margin-left: 20px;
                    margin-top: 5px;
                    margin-bottom: 5px; } }
            .contact-email {
                background-color: $allstate-secondary;
                color: white;
                padding: 10px 40px;
                margin-right: 50px;
                border-radius: 10px;
                font-weight: bold;
                text-align: center;
                a {
                    color: white;
                    font-weight: normal; } }
            .gray {
                color: #b9b9b9;
                a {
                    color: #b9b9b9; } } } } }


body.terms-of-service {
  h2 {
    text-align: center; }
  h4 {
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 15px; }
  ol {
    padding-left: 15px;
    ol {
      list-style-type: lower-alpha; } }
  ul {
    list-style-type: initial; } }

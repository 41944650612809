.modal-content {
    box-shadow: none;
    border: 0;
    border-radius: 0;
    background: transparent; }

.modal-header {
    background-color: #00467f;
    border-radius: 5px 5px 0 0;
    padding: 30px;
    position: relative;
    @include transition(.5s ease background-color);
    border: 0;

    .icon-lg {
        font-size: 90px;
        height: 90px;
        text-align: center;
        line-height: 1;
        margin: 0 0 15px;
        position: relative; }

    i[class^="icon-"] {
        height: 90px;
        width: 90px;
        left: 50%;
        margin-left: -45px;
        top: 0;
        color: white;
        @include transition(.25s ease transform);
        position: absolute;

        &:before {
            margin: 0; }

        &.ng-hide-add.ng-hide-add-active {
            @include scale(0); }

        &.ng-hide-remove {
            @include scale(0);
            @include transition(.25s ease transform .25s); }

        &.ng-hide-remove-active {
            @include scale(1); } }

    h2 {
        margin: 0 0 5px;
        color: white;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.2;
        text-align: center; }

    h3 {
        margin: 0;
        color: white;
        font-size: 14px;
        line-height: 1.2;
        text-align: center; }

    .close {
        height: 20px;
        width: 20px;
        line-height: 20px;
        font-size: 20px;
        position: absolute;
        top: 25px;
        right: 25px;
        color: $background_light;
        text-shadow: none;
        @include opacity(1);
        z-index: 1;

        &:hover {
            color: darken($background_light, 10%); } } }

.modal-body {
    background: $background_light;
    padding: 30px;
    @include transition(all .25s ease); }

.modal-footer {
    background: #f9f9f9;
    padding: 20px 30px;
    border-radius: 0 0 5px 5px;
    border: 0; }

.modal-backdrop {
    position: fixed;
    bottom: 0; }

// Form Validation

.modal-content {
    form {
        &.pristine {
            .modal-header {
                background-color: #00467f; } }

        &.invalid {
            .modal-header {
                background-color: $allstate_error; } }

        &.valid {
            .modal-header {
                background-color: $allstate_success; } } } }

.stripe {
    padding: 100px 0;
    overflow: hidden;
    min-height: 900px;

    .container {
        position: relative; }

    .content {
        width: 40%; }

    h3 {
        font-size: 28px;
        margin: 160px 0 30px;
        letter-spacing: -1px;
        color: lighten($color_text, 5%); }

    p {
        font-size: 18px;
        line-height: 1.5;
        color: lighten($color_text, 20%);
        margin: 0 0 20px; }

    &.even {
        background: white; }

    &.odd {
        background: $background_light;

        &.alt {
            background: #f4f6f8; } }

    @media (max-width: 992px) {
        min-height: 0;

        .screenshot {
            display: none; }

        .content {
            width: 100%; }

        h3 {
            margin-top: 0; } }

    @media (max-width: 768px) {
        padding: 40px 0; } }

body.home,body.signUp {
    @import "views/sign-up-modal";
    @import "views/testimonials";

    #home-hero-gif-bg {
        padding-top: 30px;
        background: rgba(255,255,255,1);
        background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 1%, rgba(242,242,242,1) 28%, rgba(236,236,236,1) 73%, rgba(255,255,255,1) 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,1)), color-stop(1%, rgba(255,255,255,1)), color-stop(28%, rgba(242,242,242,1)), color-stop(73%, rgba(236,236,236,1)), color-stop(100%, rgba(255,255,255,1)));
        background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 1%, rgba(242,242,242,1) 28%, rgba(236,236,236,1) 73%, rgba(255,255,255,1) 100%);
        background: -o-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 1%, rgba(242,242,242,1) 28%, rgba(236,236,236,1) 73%, rgba(255,255,255,1) 100%);
        background: -ms-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 1%, rgba(242,242,242,1) 28%, rgba(236,236,236,1) 73%, rgba(255,255,255,1) 100%); }

    .hero {
        padding-bottom: 0;
        margin: 0;

        .container {
            // border: 0
            // margin: 0
            // width: 100%
            // height: 30vw
            // background: url("/images/hero_images/kelsey-home.jpg") no-repeat scroll center center
            // background-size: cover

            h1 {
                padding-top: 3vw;
                margin-left: 18vw;
                text-align: left;
                font-weight: bold;
                font-size: 2.8vw;
                margin-bottom: 1.5vw; }

            img {
                // margin-left: 18vw
 }                // margin-bottom: 1.5vw

            img.hero-banner {}

            h5 {
                text-align: center;
                color: white;
                font-weight: bold;
                margin-bottom: 0;
                margin-top: -116px;
                margin-right: 25px;
                display: inline;
                float: right;
                padding-left: 40px; }

            .hero-button {
                margin-left: 18vw;
                width: 18vw;
                margin-top: 0;

                span {
                    font-size: 1vw; } }

            h3 {
                // padding-top: 1vw
                // margin-left: 18vw

                font-weight: bold;
                color: #0a67ad;
                line-height: 34px; }
            h4 {
                color: #0a67ad;
                line-height: 22px; } }

        @media (max-width: 1999px) {


            .hero .container h3 {
                text-align: center; } }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

            .container {

                h1 {
                    margin-left: 10vw;
                    font-size: 3.1vw; }

                img {
                    margin-left: 10vw; }

                img.hero-banner {
                    width: 250px; }

                h5 {
                    font-size: 10px; }

                .hero-button {
                    margin-left: 10vw;

                    span {
                        font-size: 1.4vw; } }

                h3 {
                    a {
                        font-size: 12px; } }

                h3.questions-link {
                    padding: 0;
                    margin-left: 10vw;


                    a {
                        font-size: 20px; } } } }

        @media (max-width: 768px) {
            #home-hero-gif-bg {
                padding-top: 0px; }
            .container {
                margin-top: 10px;

                h1 {
                    margin-left: 1vw;
                    font-size: 3.3vw; }

                img {
                    margin-left: 1vw; }

                img.hero-banner {
                    margin-right: 200px;
                    margin-left: 0;
                    padding-top: 30%;
                    padding-left: 0; }

                h5 {
                    font-size: 7px;
                    margin-left: 50px;
                    padding-top: 28%;
                    padding-left: 0;
                    margin-right: 175px;
                    width: 100%; }


                .hero-button {
                    margin: 0;
                    padding: 0;
                    width: 95%;
                    // height: 40px

                    span {

                        font-size: 14px; } }

                h3.questions-link {
                    padding: 0;
                    line-height: 9px; }


                h3 {
                    padding: 0;
                    margin-left: 1vw;
                    margin-top: 10px;

                    a {
                        font-size: 10px;



                        br {
                            display: none; } } } } } }

    .divider {
        background-color: #00467f;
        width: 100%;
        border: 0;
        margin-top: 0;
        padding-bottom: 15px;
        padding-top: 15px;

        h2 {
            color: white;
            font-size: 1.5vw;
            padding-bottom: 15px;
            font-weight: 300;
            line-height: 45px;

            @media (max-width: 992px) {
                font-size: 20px; } }

        h3 {
            color: white; }

        h4 {
            color: white;
            font-weight: 300;
            font-size: 1vw; }

        .percentages {
            padding-top: 15px;
            margin-left: 15vw;
            margin-right: 15vw;

            .percentage-col {
                border-right: 2px solid white;

                &:last-child {
                    border: none; }

                h1 {
                    color: white;
                    font-size: 2vw; }

                h4 {
                    color: white;
                    font-weight: 300;
                    font-size: 1vw; } } }

        p {
            color: white;
            font-size: .7vw; } }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        .divider {
            padding: 0;

            .row {
                margin: 0;

                h2 {
                    line-height: 25px; } } } }

    @media (max-width: 768px) {

        .divider {
            padding: 0;

            .row {
                margin: 0;

                h2 {
                    font-size: 3.9vw;
                    line-height: 25px; } } } }

    section.technology {
        margin-left: 16vw;
        margin-right: 16vw;
        padding-bottom: 5vw;

        .alm-web {
            width: 100%; }

        h2 {
            color: #00467f;
            padding: 30px 15px 10px; }

        .technology-container {

            h1 {
                color: white;
                margin: 0;
                font-size: 2vw;
                font-weight: 300;
                background-color: #00467f;
                padding-bottom: 10px;
                padding-top: 10px;
                margin-bottom: 30px; }

            h2 {
                font-size: 2vw;
                padding-top: 0; }

            img {
                width: 85px;
                height: 85px; }

            .boxes-light {
                background-color: $background_light;
                border: 2px solid #eaedf1;
                overflow:hidden {}
                height: 300px;
                padding: 60px 0;
                margin-bottom: 30px;
                position: relative;
                -webkit-transition: all .5s ease-in-out;
                -moz-transition: all .5s ease-in-out;
                -o-transition: all .5s ease-in-out;
                transition: all .5s ease-in-out;

                &:hover {
                    background-color: #00467f;
                    padding: 15px 0 50px;

                    .peek {
                        font-size: 50px;
                        height: 150px;

                        img {
                            width: 45px;
                            height: 45px;
                            padding-bottom: 0;
                            -webkit-transition: all .5s ease-in-out;
                            -moz-transition: all .5s ease-in-out;
                            -o-transition: all .5s ease-in-out;
                            transition: all .5s ease-in-out; }

                        .blue {
                            display: none; }

                        .white {
                            display: inline; }

                        h2 {
                            font-size: 1.6vw;
                            color: white;
                            -webkit-transition: all .5s ease-in-out;
                            -moz-transition: all .5s ease-in-out;
                            -o-transition: all .5s ease-in-out;
                            transition: all .5s ease-in-out; } }

                    .aboo {
                        height: 200px;

                        span {
                            font-size: 1vw;
                            padding-top: 0;
                            color: white;
                            -webkit-transition: all .5s ease-in-out;
                            -moz-transition: all .5s ease-in-out;
                            -o-transition: all .5s ease-in-out;
                            transition: all .5s ease-in-out; }

                        a {
                            width: 300px;
                            display: inline-block;
                            -webkit-transition: all .5s ease-in-out;
                            -moz-transition: all .5s ease-in-out;
                            -o-transition: all .5s ease-in-out;
                            transition: all .5s ease-in-out; } } } }

            .peek {
                height: 220px;

                .white {
                    display: none; } }

            .aboo {
                transition: all 1s ease;
                bottom: -300px;

                span {
                    font-size: 0px;
                    line-height: 20px;
                    font-weight: 200; }

                a {
                    display: none; } } } }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

        section.technology {
            margin-left: 3vw;
            margin-right: 3vw;

            .technology-container {

                .boxes-light {
                    padding: 30px 0;
                    height: 220px;

                    &:hover {
                        padding: 10px 0 30px;

                        .peek {
                            font-size: 30px;
                            height: 90px; }

                        .aboo {
                            height: 80px;

                            span {
                                padding: 0;
                                margin: 0;
                                line-height: 10px; } } } } } } }


    @media (max-width: 768px) {
        section.technology {
            margin: 0;

            .technology-container {

                h1 {
                    display: none; }

                h2 {
                    font-size: 6vw;
                    padding-top: 0; }

                img {
                    width: 60px;
                    height: 60px; }

                .boxes-light {
                    height: 200px;
                    padding-top: 30px;

                    &:hover {
                        padding: 0;

                        .peek {
                            font-size: 30px;
                            height: 85px;


                            img {
                                width: 30px;
                                height: 30px;
                                padding-bottom: 0; }

                            h2 {
                                font-size: 4.5vw;
                                padding: 0; } }

                        .aboo {
                            bottom: 10px;
                            height: 100px;

                            span {
                                font-size: 10px; }


                            a {
                                width: 150px;
                                display: inline-block; } } } }

                .peek {
                    height: 100px; }

                .aboo {
                    transition: all 1s ease;
                    bottom: -300px;

                    span {
                        font-size: 0px;
                        line-height: 20px;
                        font-weight: 200; }

                    a {
                        display: none; } } } } } }

.screenshot {
    border-radius: 5px;
    border-top: 28px solid $background_light;
    position: absolute;
    left: 50%;
    top: 0;

    &:before {
        // position: absolute
        // height: 30px
        // line-height: 30px
        // top: -28px
        // left: 12px
        // content: "\e811\00a0\00a0\e811\00a0\00a0\e811"
        // font-family: "ue-icons"
        font-style: normal;
        font-weight: normal;
        speak: none;
        display: inline-block;
        color: darken($background_light, 20%);
        font-size: 12px; }

    img {
        // border: 2px solid $background_light
        border-radius: 0 0 5px 5px;
        max-width: 1140px; }

    &.dark {
        border-color: $allstate_primary;

        &:before {
            color: lighten($allstate_primary, 40%); }

        img {
            border-color: $allstate_primary; } } }

@import url(//fonts.googleapis.com/css?family=Open+Sans:200,300,400,500,600);
header.hero {
    // background: url("../images/hero/home.jpg") no-repeat bottom center $allstate_primary
    background-color: white;
    padding-top: 120px;

    h1 {
        font-family: 'Open Sans', sans-serif;
        margin: 0 0 0;
        color: #00467f;
        text-align: center;
        padding-top: 60px;
        font-size: 40px;
        font-weight: normal;
        // letter-spacing: -1px
        line-height: 1;
        -webkit-font-smoothing: antialiased; }

    h2 {
        margin: 20px 0 50px;
        color: $background-light;
        font-size: 30px;
        font-weight: normal; }

    .btn {
        width: 50%; }

    .btn-toolbar {
        margin-left: -15px;

        & > .btn {
            margin-left: 15px; } }

    .video-thumb {
        margin: 80px 0 0;
        position: relative;

        .play-btn {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -30px;
            margin-top: -30px;
            height: 60px;
            width: 60px;
            color: white;
            border: 3px solid white;
            border-radius: 30px;
            font-size: 28px;
            line-height: 60px;
            text-align: center;
            background-color: transparent;
            @include transition(.3s ease all);
            overflow: hidden;

            .icon-play {
                position: absolute;
                top: -1px;
                left: 16px;

                &:before {
                    margin: 0; } }

            .btn-text {
                color: transparent;
                display: inline-block;
                width: 200px;
                overflow: hidden;
                font-size: 18px;
                line-height: 57px;
                @include transition(.3s ease width); }

            &:hover {
                width: 200px;
                margin-left: -100px;
                background-color: white;
                border-color: transparent;
                cursor: pointer;

                .btn-text {
                    color: $allstate_primary;
                    margin-left: 0; } } }

        img {
            border-radius: 10px;
            max-width: 100%; } }

    @media screen and (max-width: 992px) {
        text-align: center;
        height: auto !important;
        padding-bottom: 80px;

        h1 {
            margin-top: 0; }

        .video-thumb {
 } } }            // display: none

body {
    font-family: "Open sans", "Sans-serif";

    & > iframe {
        height: 0 !important;
        display: none; }

    & > img {
        height: 0;
        display: none; } }

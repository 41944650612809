body.about {

    .hero {
        padding-bottom: 0;
        margin: 0;

        .container {
            margin: 0;
            width: 100%;
            padding-bottom: 0;
            height: 600px;
            border: 0;
            background: url("/images/hero_images/about-hero.png") no-repeat center center;
            background-size: cover;

            h1 {
                font-size: 3.3vw;
                color: white;
                font-weight: bold;
                padding-top: 200px;
                text-shadow: 0.075em 0.08em 0.1em rgba(0, 0, 0, 1); }

            .video-thumb {
                padding-bottom: 0;
                // position: relative
                // text-align: center
                // background-color: white
                // // width: 800px
                // border: 2px solid #00467f
                // box-shadow: 0 10px 2px -2px #bdbdbd

                @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
                    border: none; }

                @media (max-width: 768px) {
                    width: 100%;
                    padding-top: 120px;
                    margin: 0; }

                .before-play-button {
                    color: #00467f;
                    padding-top: 150px;
                    padding-bottom: 150px;

                    @media (max-width: 768px) {
                        padding-top: 20px;
                        font-size: 20px; } }

                .after-play-button {
                    color: #ee8722;

                    @media (max-width: 768px) {
                        font-size: 20px;
                        padding-bottom: 50px; } }

                .play-btn {
                    // position: absolute
                    left: 50%;
                    top: 80%;
                    margin-left: -30px;
                    margin-top: -30px;
                    height: 60px;
                    width: 60px;
                    color: white;
                    // border: 3px solid #ee8722
                    border-radius: 30px;
                    font-size: 28px;
                    line-height: 60px;
                    text-align: center;
                    background-color: #00467f;
                    @include transition(.3s ease all);
                    overflow: hidden;

                    .icon-play {
                        position: absolute;
                        top: -3px;
                        left: 16px;

                        &:before {
                            margin: 0; } }

                    .btn-text {
                        color: transparent;
                        display: inline-block;
                        width: 200px;
                        overflow: hidden;
                        font-size: 18px;
                        line-height: 57px;
                        @include transition(.3s ease width); }

                    &:hover {
                        width: 200px;
                        margin-left: -100px;
                        background-color: #d3d3d3;
                        border-color: transparent;
                        cursor: pointer;

                        .btn-text {
                            color: #00467f;
                            margin-left: 0; } } } } }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

            .container {} }


        @media (max-width: 768px) {
            .container {

                h1 {
                    font-size: 30px; } } } }



    .divider {
        background-color: #00467f;
        width: 100%;
        border: 0;
        padding-bottom: 15px;
        padding-top: 15px;

        h2 {
            color: white;
            font-size: 1.5vw;
            padding-bottom: 15px;
            font-weight: 300;
            line-height: 45px;

            @media (max-width: 768px) {
                font-size: 20px; } } }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        .divider {
            padding: 0;

            .row {
                margin: 0;

                h2 {
                    line-height: 25px; } } } }

    @media (max-width: 768px) {

        .divider {
            padding: 0;

            .row {
                margin: 0;

                h2 {
                    font-size: 3.9vw;
                    line-height: 25px; } } } }

    .timeline-container {
        margin-top: 7vw;
        margin-bottom: 10vw;
        padding: 0;


        .timeline {
            list-style: none;
            padding: 20px 0 20px;
            position: relative; }

        .timeline:before {
            top: 0;
            bottom: 0;
            position: absolute;
            content: " ";
            width: 5px;
            background-color: #00467f;
            left: 50%;
            margin-left: -1.5px; }

        .timeline > li {
            margin-bottom: -30px;
            position: relative; }


        .timeline > li:before,
        .timeline > li:after {
            content: " ";
            display: table; }

        .timeline > li:after {
            clear: both; }

        .timeline > li:before,
        .timeline > li:after {
            content: " ";
            display: table; }

        .timeline > li:after {
            clear: both; }

        .timeline > li > .timeline-panel {
            width: 530px;
            float: left;
            border-radius: 2px;
            position: relative;
            cursor: pointer;

            &:hover {
                .timeline-body > h2 {
                    color: $allstate_warning; }

                .timeline-text {
                    visibility: visible;
                    animation: fadeIn .3s;
                    -webkit-animation: fadeIn .3s;
                    -moz-animation: fadeIn .3s;
                    -o-transition: fadeIn .3s;
                    -webkit-transition: all .3s ease-in-out;
                    -moz-transition: all .3s ease-in-out;
                    -o-transition: all .3s ease-in-out;
                    transition: all .3s ease-in-out; } } }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            .timeline > li > .timeline-panel {
                width: 420px; } }

        .timeline > li > .timeline-panel:before {
            position: absolute;
            top: 26px;
            right: -14px;
            display: inline-block;
            border-top: 15px solid transparent;
            border-left: 15px solid #00467f;
            border-right: 0 solid #00467f;
            border-bottom: 15px solid transparent;
            content: " "; }

        .timeline > li > .timeline-panel:after {
            position: absolute;
            top: 27px;
            right: -14px;
            display: inline-block;
            border-top: 14px solid transparent;
            border-left: 14px solid #00467f;
            border-right: 0 solid #00467f;
            border-bottom: 14px solid transparent;
            content: " "; }

        .timeline > li > .timeline-badge {
            color: #fff;
            width: 20px;
            height: 20px;
            line-height: 50px;
            font-size: 1.4em;
            text-align: center;
            position: absolute;
            top: 28px;
            left: 49.2%;
            // margin-left: -25px
            background-color: #00467f;
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%; }


        .timeline > li > .timeline-firstYear {
            color: #fff;
            width: 70px;
            height: 70px;
            line-height: 100px;
            font-size: 1.4em;
            text-align: center;
            position: absolute;
            top: -90px;
            left: 47.1%;
            // margin-left: -25px
            background-color: #00467f;
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;

            p {
                line-height: 70px; } }

        .timeline > li > .timeline-secondYear {
            color: #fff;
            width: 70px;
            height: 70px;
            line-height: 100px;
            font-size: 1.4em;
            text-align: center;
            position: absolute;
            top: -140px;
            left: 47.1%;
            // margin-left: -25px
            background-color: #00467f;
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;

            p {
                line-height: 70px; } }

        .timeline > li > .timeline-thirdYear {
            color: #fff;
            width: 70px;
            height: 70px;
            line-height: 100px;
            font-size: 1.4em;
            text-align: center;
            position: absolute;
            top: -140px;
            left: 47.1%;
            // margin-left: -25px
            background-color: #00467f;
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;

            p {
                line-height: 70px; } }

        .timeline > li > .timeline-fourthYear {
            color: #fff;
            width: 70px;
            height: 70px;
            line-height: 100px;
            font-size: 1.4em;
            text-align: center;
            position: absolute;
            top: -140px;
            left: 47.1%;
            // margin-left: -25px
            background-color: #00467f;
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;

            p {
                line-height: 70px; } }

        .timeline > li > .timeline-today {
            color: #fff;
            width: 70px;
            height: 70px;
            line-height: 100px;
            font-size: 1.4em;
            text-align: center;
            position: absolute;
            top: -220px;
            left: 47.1%;
            // margin-left: -25px
            background-color: #00467f;
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;

            p {
                line-height: 70px; } }

        .timeline > li > .timeline-nextYear {
            color: #fff;
            width: 70px;
            height: 70px;
            line-height: 100px;
            font-size: 1.4em;
            text-align: center;
            position: absolute;
            top: 236px;
            left: 47.1%;
            // margin-left: -25px
            background-color: #00467f;
            z-index: 100;
            border-top-right-radius: 50%;
            border-top-left-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;

            p {
                line-height: 70px; } }


        .timeline > li.timeline-inverted > .timeline-panel {
            float: right;
            padding-right: -15px; }

        .timeline > li.timeline-inverted > .timeline-panel:before {
            border-left-width: 0;
            border-right-width: 15px;
            left: -14px;
            right: auto; }

        .timeline > li.timeline-inverted > .timeline-panel:after {
            border-left-width: 0;
            border-right-width: 15px;
            left: -14px;
            right: auto; }

        .timeline-badge.primary {
            background-color: #2e6da4 !important; }

        .timeline-badge.success {
            background-color: #3f903f !important; }

        .timeline-badge.warning {
            background-color: #f0ad4e !important; }

        .timeline-badge.danger {
            background-color: #d9534f !important; }

        .timeline-badge.info {
            background-color: #5bc0de !important; }

        .timeline-body {
            margin: 0;
            height: 120px;
            background-color: #00467f; }

        .timeline-body > p,
        .timeline-body > ul {
            margin-bottom: 0; }

        .timeline-body > h2 {
            color: white;
            font-size: 1.5vw;
            margin: 0;
            margin-top: -18px;
            padding-top: 1.3vw;
            padding-left: 1.3vw;
            padding-bottom: 1.3vw;
            font-weight: 300;
            background-color: #00467f; }

        .timeline-body > h2.linedUp {
            line-height: 70px; }

        .timeline-body > h2.twoLined {
            padding-top: 45px; }

        .timeline-body > p + p {
            margin-top: 5px; }


        .timeline-text {
            margin: 0;
            padding-left: 1.3vw;
            background-color: $background_light;
            padding-bottom: 1vw;
            padding-top: 1vw;
            visibility: hidden;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;

            p {
                font-size: 20px;
                padding-right: 20px; } }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

            .timeline-body {
                height: 80px; }

            .timeline-body > h2 {
                font-size: 24px;

                br {
                    display: none; } }

            .timeline-body > h2.linedUp {
                margin-top: -2px;
                line-height: 60px; }

            .timeline-body > h2.twoLined {
                margin-top: -2px;
                padding-top: 25px; }

            .timeline > li > .years {
                left: 46.5%;
                top: -89px; }

            .timeline > li > .timeline-today {
                top: -150px; }

            .timeline > li > .timeline-nextYear {
                top: 190px; }

            .timeline-text {
                p {
                    font-size: 17px;
                    padding-right: 10px; } } }


        @media (max-width: 767px) {
            ul.timeline:before {
                left: 40px; }
            ul.timeline > li > .timeline-panel {
                width: calc(100% - 90px);
                width: -moz-calc(100% - 90px);
                width: -webkit-calc(100% - 90px);
                padding-bottom: 20px;
                margin-right: 10px; }
            ul.timeline > li > .timeline-badge {
                left: 34px;
                margin-left: 0;
                top: 62px;
                width: 15px;
                height: 15px; }

            ul.timeline >li > .years {
                left: 22px;
                top: -41px;
                width: 35px;
                height: 35px;

                p {
                    line-height: 37px;
                    font-size: 12px; } }

            ul.timeline > li > .timeline-today {
                top: -140px; }

            ul.timeline > li > .timeline-nextYear {
                top: 210px; }

            ul.timeline > li > .timeline-panel {
                float: right; }
            ul.timeline > li > .timeline-panel:before {
                border-left-width: 0;
                border-right-width: 15px;
                left: -15px;
                right: auto; }
            ul.timeline > li > .timeline-panel:after {
                border-left-width: 0;
                border-right-width: 14px;
                left: -14px;
                right: auto; }

            ul.timeline > li > .timeline-panel > .timeline-body {
                height: 70px;

                h2 {
                    font-size: 16px;
                    padding-top: 15px;
                    margin-top: 5px; }

                .linedUp {
                    padding-top: 0px;
 }                    // line-height: 40px

                .twoLined {
                    padding-top: 25px; } }

            ul.timeline > li > .timeline-panel > .timeline-text {

                p {
                    font-size: 14px;
                    padding-right: 5px; }

                br {
                    display: none; } }

            ul.timeline > li.timeline-inverted {
                margin-top: 0; } } }


    .definition {
        margin-top: 70px;
        margin-bottom: 100px;
        animation: fadeIn 2s;
        -webkit-animation: fadeIn 2s;
        -moz-animation: fadeIn 2s;
        -o-transition: fadeIn 2s;

        h2 {
            color: #00467f;
            font-size: 2vw;
            margin: 0; }

        h2.orange {
            color: $allstate_warning;
            padding-bottom: 30px; }

        h4 {
            font-size: 20px;
            color: #00467f;
            line-height: 32px; } }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

        .definition {
            padding: 0;
            margin-top: 20px;

            .row {
                margin: 0;

                h4 {
                    font-size: 18px;
                    line-height: 25px; } } } }

    @media (max-width: 768px) {

        .definition {
            padding: 0;
            margin-left: 10px;
            margin-right: 10px;

            .row {
                margin: 0;

                h2 {
                    font-size: 14px; }

                h4 {
                    font-size: 14px;
                    line-height: 16px;
                    padding-left: 10px; }

                br {
                    display: none; } } } } }

.gray-area {
    background-color: $background_light;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 50px;

    .row {
        h2 {
            color: #00467f; }

        h4 {
            color: $allstate_warning;
            line-height: 30px;
            font-weight: bold; }

        a {
            color: $allstate_warning;
            cursor: pointer; }

        p {
            font-size: 16px; }

        .social-icons {

            img {
                margin-right: 1vw; } } } }

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .gray-area {
        margin: 0;

        .row {
            margin: 0;

            h2 {
                font-size: 2.5vw; }

            p {
                font-size: 1.5vw; } } } }




@media (max-width: 768px) {
    .gray-area {

        .row {
            margin: 0;

            h2 {
                font-size: 4.2vw; }

            p {
                font-size: 4vw; } } } }


#footer {
    background-color: #00467f;
    padding: 20px 0 20px;

    .brand a {
       display: inline-block;
       max-width: 100%;
       width: 93px;
       padding-top: 10px; }

    .site-id-vector {
        fill: #fff; }
    .footer-row {
        // padding-left: 58px

        .brand {
            margin-bottom: 0px;
 }            // padding-left: -70px

        .buttons {
            display: inline-block;
            padding: 0;
            padding-top: 7px;

            a.signup {
                width: 270px;
                height: 50px; }

            a.login {
                width: 270px;
                height: 50px; } }




        @media (max-width: 992px) {
 }            // padding-left: 80px

        font-size: 18px; }
    h4 {
        color: white;
        font-weight: bold;
        font-size: 22px;
        padding: 0 8px;
        padding-right: 90px;
        text-transform: uppercase; }

    .footer-bottom {
        margin-top: 35px;
        margin-bottom: 15px;
        font-size: 14px;
        color: #fff;
        a {
            color: #fff; } } }

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

    #footer {

        .footer-row {

            .buttons {

                a.signup {
                    width: 200px;
                    height: 50px; }

                a.login {
                    width: 200px;
                    height: 50px; } } } } }


@media (max-width: 992px) {

    #footer {

        .footer-row {
            text-align: center;

            .brand {
                img {
                    display: none;
                    // width: 150px
                    // height: 30px
 } }                    // text-align: left

            .buttons {
                padding-top: 10px;
                display: inline;
                a.signup {
                    width: 200px;
                    height: 50px;
                    font-size: 16px; }

                a.login {
                    width: 200px;
                    height: 50px;
                    font-size: 16px; } } } } }
@media (max-width: 767px) {
    #footer {
        .footer-row .btn-block {
            display: inline-block; } } }

.navbar-container {
    width: 100% !important;
    z-index: 1000;

    .navbar-default {
        border-radius: 0;
        border: 0;
        margin: 0;
        background: transparent;

        .navbar-collapse {
            border: 0;
            box-shadow: none; }

        .navbar-brand {
            position: absolute;
            top: 20px;
            left: 0;
            max-width: 100%;
            width: 7em;
            height: auto;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            padding: 0;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                height: 130px; } }
        .site-id-vector {
            fill: #0a67ad;
            transition: all 700ms ease;

            @media (max-width: 768px) {

                img {
                    margin-top: -30px;
                    width: 65vw;
                    height: 7vw;
                    margin-left: 30px; } } }

        .navbar-nav {
            text-align: right;
            border: 0;
            float: right;
            //margin-right: -28px
            padding-right: 0px;
            padding-top: 0px;
            font-size: 17px;
            font-family: 'Open Sans', sans-serif;


            & > li {
                & > a {
                    background-color: transparent;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 14px;
                    padding-right: 14px;
                    // font-weight: bold

                    &:hover, &:active {
                        background-color: transparent; } }

                &.active {
                    & > a {
                        background-color: transparent;

                        &:hover, &:active {
                            background-color: transparent; } } }

                &.action {
                    margin-left: 20px;

                    & > a {
                        padding: 0; } } } }

        @media (max-width: 768px) {

            .navbar-brand {
                width: 22%;
 }                //top: 25px
            .navbar-nav {
                //width: 100%
 }                //margin: 15px 0

            .navbar-collapse {
                padding: 0; }

            .nav {
                & > li {
                    & > a {
                        // padding: 10px 0
                        text-align: center;
                        .btn {
                            width: 100%; } }

                    &.action {
                        //padding: 10px 0
                        //margin: 0
 } } } } }                        //margin: 10px 20px

    &.menu-open {
        @media (max-width: 767px) {
            .collapse {
                display: block;
                visibility: visible;
                .navbar-nav {
                    float: none;
                    background-color: #fff;
                    a {
                        color: #00467f !important; }
                    .action {
                        margin: 10px 25px; } } } } }
    &.affix-top {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        background-color: white;
        .navbar-default {
            position: relative;
            height: 120px;
            padding: 40px 0;
            .navbar-nav {
                & > li {
                    & > a {
                        color: #00467f;

                        &:hover, &:active, &:focus {
                            color: $allstate_secondary; }

                        & > .login {
                                background-color: #00467f;
                                border: 0; } }

                    &.active {
                        & > a {
                            // color: white

                            &:hover, &:active {
                                color: $allstate_secondary; } } } } } }

        &.menu-open {
            @media (max-width: 767px) {
 } } }                //background-color: white

    &.affix, &.affix-add {
        @include transition(0.3s ease transform);
        background-color: #00467f;
        z-index: 1030;
        box-shadow: (0 2px 4px 0 rgba(62, 68, 79, .2));

        .site-id-vector {
            fill: #fff; }

        .navbar-default {
            height: 120px;
            padding: 40px 0;

            .navbar-collapse {
                background: transparent; }
            .cls-1 {
                fill: #fff; }
            .navbar-nav {
                & > li {
                    & > a {
                        color: white;

                        &:hover, &:active, &:focus {
                            color: $allstate_warning; }

                        & > .login {
                                background-color: white;
                                color: #00467f;
                                border: 0;

                                &:hover, &:active, &:focus {
                                    background-color: $background_light; } } }


                    &.active {
                        & > a {
 }                            // color: white

                        &:hover, &:active {
                            color: $allstate_warning; } } } } }
        .navbar-toggle {
            .icon-bar {
                background-color: lighten($allstate_primary, 10%); } } }

    &.affix-add {
        @include translate3d(0, -90px, 0); }

    &.affix-add-active {
        @include translate3d(0, 0, 0); }

    .navbar-toggle {
        margin: 9px 0;
        border: 0;
        background: transparent;

        .icon-bar {
            background-color: $background_light; }

        &:hover, &:active, &:focus {
            background: transparent;

            .icon-bar {
                background-color: $allstate_warning; } } } }
@media (min-width: 0px) and (max-width: 767px) {
    .navbar-brand {
        width: 22% !important;
        /*top: 33px !important */ } }

body.contact {
    header.hero {
        @media (max-width: 992px) {
            padding-bottom: 0; }
        .contact-container {
            border: 0;
            margin: 0;
            width: 100%;
            background: url("/images/hero_images/contact_hero_transparent.jpg") no-repeat center center;
            background-size: cover;
            padding-bottom: 0px; }
        .faq {
            background-color: #fff;
            margin: 50px 0;
            padding: 47px 23px;
            border: 1px solid #0097d3;
            h3 {
                -webkit-font-smoothing: antialiased;
                font-size: 26px;
                color: #0096d6;
                font-weight: bolder;
                line-height: 30px;
                border-bottom: 2px solid #ee8722;
                line-height: 75px;
                margin-top: 0;
                margin-bottom: 36px; }
            .question-answer {
                margin: 0;
                padding: 0;
                list-style-type: none;
                margin: 20px 0;
                &.last {
                    margin-bottom: 50px; }
                .question,.answer {
                    font-weight: bold;
                    font-size: 14px;
                    color: #00467f; }
                .question {
                    line-height: 30px; }
                .question ul {
                    margin: 0;
                    padding: 0; }
                .answer {
                    font-weight: normal;
                    list-style-type: none;
                    line-height: 22px;
                    a {
                        color: #00467f; } }
                .answer.list-style {
                    list-style-type: disc;
                    margin-left: 20px;
                    margin-top: 5px;
                    margin-bottom: 5px; } }
            .contact-email {
                background-color: $allstate-secondary;
                color: white;
                padding: 10px 40px;
                margin-right: 50px;
                border-radius: 10px;
                font-weight: bold;
                text-align: center;
                a {
                    color: white;
                    font-weight: normal; } }
            .gray {
                color: #b9b9b9;
                a {
                    color: #b9b9b9; } } }
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            .contact-container {
                .header-row {
                    margin-left: 20px;
                    margin-right: 20px;
                    .header-container {
                        width: 100%;
                        h3.h3-left {
                            font-size: 16px;
                            line-height: 20px;
                            margin-left: 0; }
                        h3.h3-right {
                            font-size: 16px;
                            line-height: 20px; }
                        .contact-hero-left {
                            margin-right: 0;
                            margin-left: 0;
                            padding-top: 10px;
                            padding-bottom: 10px; }
                        .contact-hero-right {
                            margin-left: 0;
                            padding-top: 10px;
                            padding-bottom: 10px; } } }
                h3 {
                    padding-bottom: 15px; } } }
        @media (max-width: 992px) {
            .contact-container {
                margin: 0;
                padding: 0;
                .header-row {
                    margin: 0;
                    .header-container {
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        h3.h3-left {
                            font-size: 12px;
                            line-height: 15px;
                            margin-left: 0;
                            margin-top: 15px;
                            margin-bottom: 15px; }
                        h3.h3-right {
                            font-size: 12px;
                            line-height: 15px;
                            margin-top: 15px;
                            margin-bottom: 15px; }
                        .contact-hero-left {
                            margin-right: 0;
                            margin-left: 0;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            height: 420px;
                            width: 100%; }
                        .contact-hero-right {
                            margin-left: 0;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            height: 250px;
                            width: 100%; }
                        ul {
                            li {
                                font-size: 10px; }
                            hr {
                                width: 150px;
                                margin-top: 5px;
                                margin-bottom: 5px; } }
                        .contact-email {
                            font-size: 10px; }
                        .icon-lg {
                              height: 140px;
                              width: 140px;
                              padding: 40px 0;
                              border-radius: 50%;
                              border: 2px solid lighten($allstate_primary, 15%);
                              background-color: lighten($allstate_primary, 8%);
                              color: white;
                              color: $background_light;
                              font-size: 60px;
                              text-align: center;
                              display: block;
                              margin: 0 auto 20px;
                              i[class^="icon-"] {
                                  display: block;
                                  margin: 0 auto;
                                  height: 60px;
                                  width: 60px;
                                  line-height: 60px;
                                  &:before {
                                      margin: 0; } } } } }
                h3 {
                    padding-bottom: 10px;
                    font-size: 12px; } } } }
    .divider {
        background-color: #00467f;
        width: 100%;
        border: 0;
        padding-bottom: 4vw;
        padding-top: 4vw;
        a {
            color: white;
            font-weight: normal; }
        a:hover {
            cursor: pointer;
            text-decoration: none;
            color: #ee8722; }
        img {
            width: 3.5vw; }
        h3 {
            color: $allstate-warning;
            font-weight: bolder;
            padding-bottom: 20px;
            font-size: 28px; }
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            h3 {
                font-size: 20px; } }
        @media (max-width: 992px) {
            h3 {
                font-size: 14px;
                padding-bottom: 5px; } } }
    .contact-info {
        display: inline; }
    h2.support {
        -webkit-font-smoothing: antialiased;
        color: white;
        font-size: 2vw;
        font-weight: 300;
        @media (max-width: 992px) {
            padding: 0;
            font-size: 18px; } }

    hr.sidebar {
        border-bottom: 2px solid $background_light;
        width: 20vw;
        display: inline-block;
        vertical-align: middle;
        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            width: 200px; }
        @media (max-width: 992px) {
            width: 50px; } }
    .stripe {
        min-height: 0;
        padding: 60px 0 80px;
        background-color: white;
        @media (max-width: 992px) {
            padding: 20px 0 40px; }
        h2.options-header {
            -webkit-font-smoothing: antialiased;
            text-align: center;
            color: #00467f;
            font-size: 28px;
            font-weight: 400;
            padding-top: 20px;
            @media (max-width: 992px) {
                font-size: 20px;
                padding: 0; } }
        .regional {
            @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
                h2 {
                    font-size: 20px; } } }
        img {
            margin: 40px; }
        a {
            color: #ee8722;
            text-decoration: none; }
        a:hover {
            text-decoration: none;
            color: darken(#ee8722, 10%); } }
    .map-tile {
        border-radius: 5px;
        overflow: hidden;
        background-color: white;
        .map {
            background: url("../images/map.jpg") no-repeat center center;
            height: 400px; }
        .contact-info {
            padding-top: 20px;
            padding-bottom: 20px; }
        .dl-horizontal {
            padding: 20px 30px 0;
            dt {
                font-size: 36px;
                color: $allstate_secondary;
                @media (min-width: 768px) {
                    width: 50px; } }
            dd {
                font-size: 18px;
                color: lighten($color_text, 10%);
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                a {
                    color: lighten($color_text, 10%);

                    &:hover, &:active, &:focus {
                        color: $allstate_secondary;
                        text-decoration: none; } }
                @media (min-width: 768px) {
                    margin-left: 65px;
                    padding-top: 12px; } } } } }

.allstate-support, .leadvantage-support {
    .support-request {
        margin: 30px 0px;

        .form-control {
            border-color: lighten($allstate_secondary, 10%); }

        .form-control:focus {
            border-color: $allstate_secondary; } }

    .btn.btn-primary.btn-block {
        width: 100%; }
    .hero {
        h1 {
            text-align: left; } } }

.leadvantage-support {
    .support-request {
        .form-control {
            border-color: lighten($leadvantage_secondary, 20%); }

        .form-control:focus {
            border-color: $leadvantage_secondary; }

        .btn.btn-primary.btn-block {
            background-color: $leadvantage_success;
            border-color: $leadvantage_success; } } }




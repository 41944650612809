// Allstate Branded Colors
$allstate-primary: #3e444f;
$allstate_primary: #3e444f;
$allstate-secondary: #0096d6;
$allstate_secondary: #0096d6;
$allstate-error: #ee3424;
$allstate_error: #ee3424;
$allstate-warning: #ee8722;
$allstate_warning: #ee8722;
$allstate-success: #6db33f;
$allstate_success: #6db33f;

// Custom Colors
$background-light: #eaedf1;
$background_light: #eaedf1;
$color-text: #6d717a;
$color_text: #6d717a;
